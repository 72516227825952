<template>
  <!-- begin::Scrolltop -->
  <div id="dp_scrolltop"
       ref="dp_scrolltop"
       class="scrolltop">
    <i class="far fa-caret-square-up"></i>
  </div>
  <!-- end::Scrolltop -->
</template>

<script>
import DPLayoutScrolltop from "@/assets/js/layout/extended/scrolltop.js";

export default {
  name: "DPScrollTop",
  mounted() {
    // Init Scrolltop
    DPLayoutScrolltop.init(this.$refs["dp_scrolltop"]);
  },
};
</script>
